.custom-classname.react-toggle--checked .react-toggle-track {
  background-color: #272d65;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5c6770;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #272d65;
}
