.card {
  @apply bg-white shadow-card rounded-3xl flex items-center;
}

.container {
  @apply rounded-lg flex justify-between py-10;
}

.leftSide .rightSide {
  @apply border-y-2 border-r-2 rounded-r-[64px] border-new-green p-10;
}

@media (min-width: 1260px) {
  .container {
    @apply bg-new-blue;
    /* @apply bg-new-blue max-w-[1559px] mx-auto; */
  }
  .leftSide {
    @apply border-y-2 border-r-2 rounded-r-[64px] border-new-green p-10;
  }
  .rightSide {
    @apply border-y-2 border-l-2 rounded-l-[64px] border-new-green p-10;
  }
}

@media (max-width: 1259px) {
  .container {
    @apply flex-col gap-20;
  }
  .leftSide,
  .rightSide {
    @apply bg-new-blue rounded-3xl px-5;
  }
}

@media (min-width: 900px) {
  .card {
    @apply h-[257px] w-[242px] flex-col justify-center;
  }
}

@media (max-width: 899px) {
  .card {
    @apply w-full justify-start pl-5 h-[88px] gap-5;
  }
}
